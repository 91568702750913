.desDinodeHead{text-align: center;margin-bottom: 40px;}
.desginidmainweb{display: grid;grid-template-columns: repeat(4, 1fr);grid-gap: 24px;}
.desginwebTiles img{vertical-align: middle;max-height: 240px;border-radius: 5px;}  
.dedinIdeammobbox{display: none;}
.desIdealastile{background: #272c59;padding: 20px 16px;border-radius: 2px;display: flex;flex-direction: column;justify-content: space-between;border-radius: 5px;}
.desIdealastile strong{font-size: 36px;color: #eb5976;display: block;margin-bottom: 20px;}
.desIdealastile p{font-size: 36px;color: hsl(0, 0%, 100%);line-height: 40px;}
.desIdealastile .downLoadbrbox{padding: 14px 8px;display: flex;cursor: pointer;align-items: center;gap: 10px;justify-content: center;border-radius: 2px;font-size: 14px;}
@media screen and (max-width: 1024px){
.desginidmainweb{display: grid;grid-template-columns: repeat(3, 1fr);grid-gap: 24px;}
.desIdealastile strong {font-size: 26px;}
.desIdealastile p{font-size: 20px;line-height: 24px;}
}
@media screen and (max-width: 540px){
 .desDinodeHead{margin-bottom: 20px;}
.desginIdeamaxt{display: flex;gap: 16px;margin-bottom: 20px;overflow: auto;}
.desginterntiles{width: 100%;}
.desginterntiles img{vertical-align: middle;min-width: 274px;max-width: 274px;max-height: 210px;}   
.modulerDownnow{padding: 20px 16px; background: #1c2f59;border-radius: 2px;display: flex;justify-content: space-between;align-items: center;}
.modulerDownnow span{font-size: 20px;color: #eb5976;font-weight: bold;display: block;margin-bottom: 5px;}
.modulerDownnow p{font-size: 20px;color: #fff;}
.modulerDownnow button{padding:6px 8px;border-radius: 2px;background-color: #eb5976;}
.dedinIdeammobbox{display: block;}
.desginidmainweb{display: none;}
.typeOfModular li {padding: 0px 12px; font-size: 10px;}
.exploreKitmian{padding: 20px;}
.suBtext{width: 240px;}
.desginlaberyCardsclick .viewAllBtn{position: relative;z-index: 1;}
}

.typeOfModular{margin:0 auto 24px;display: flex; gap: 10px;max-width: 1170px;}
.typeOfModular button{background-color: #fff; box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.25);width: 40px;height: 40px;cursor: pointer;border-radius: 5px;}
.typeOfModular button.leftRght{transform: rotate(180deg);}
.typeOfModular ul{overflow: auto;width: 100%;white-space: nowrap;text-align: center; scroll-behavior: smooth;}
.toptrandHead h2{text-align: center;}
.toptrandHead p{text-align: center;}
.typeOfModular ul::-webkit-scrollbar {height: 0;}
.typeOfModular li{margin-left: 16px;padding: 0px 16px; color: #666; display: inline-block;border-radius: 5px;line-height: 38px;cursor: pointer;font-size: 14px; background: #eee;}
.typeOfModular li:first-child{margin: 0;}
.typeOfModular li.active{background-color: #eb5976;color: #fff; border-radius: 5px;}
.exploreKitmian{background: #fff4f6; border-radius: 5px; padding: 24px 64px;}
.exploreKitmian ul{display: flex; flex-wrap: wrap; gap: 16px; width: 100%; justify-content: center;}
.exploreKitmian ul li{padding: 0px 10px; display: inline-block;border-radius: 5px;line-height: 35px;cursor: pointer; background: #fff;}
.exploreKittoles{display: flex; gap: 10px; justify-content: center; align-items: center;}
.exploreKittoles p{ color: #794e57; font-size: 14px;}
.suBtext {color: #fff;}
.suBtext > a {color: #eb5976 !important;}

.desginlaberyCardsclick{position: relative;padding: 24px;background: #272C59; border: 1px solid #eb5976; border-radius: 10px;}
.desginlaberyCardsclick .disdinlibryimg{position: absolute; bottom: 0; right: 0;}
.desginlaberyCardsclick .viewAllBtn{padding: 6px 17px;background: #eb5976; border-radius: 5px; font-size: 12px; color: #fff;cursor: pointer;}
.containerFluid{max-width: 1350px;}

.leftArrow{color: #000;width: 40px;height: 40px;  background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;}
.rightArrow{color: #000;width: 40px;height: 40px;  background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;}
.leftArrow:hover{background-color: rgba(225, 225, 225, 1);transition: 0.3s;} 
.rightArrow:hover{background-color: rgba(225, 225, 225, 1);transition: 0.3s;}