.beutiFullsecmain{position: relative;padding-top: 60px;background: #f8f8f8;}
.beautifulseimgbox{width: 100%;text-align: center;}
.beautifulseimgbox img{vertical-align: middle;}
.beautifulstextbox{position: absolute;top: 130px;text-align: center;right: 200px;}
.beautifulstextbox .beautifulstrog{font-size: 50px;color: #222;line-height: 1.4;}
.beautifulstextbox .beautifulspan{font-size: 50px;color: #222;display: block;line-height: 1.2;margin-bottom: 20px;}
.beautifulstextbox .beautifulbtn{padding: 15px 16px; font-size: 18px;border-radius: 2px;cursor: pointer;}
.beftifulmobimgbox{display: none;}

@media screen and (max-width:1024px){
    .beautifulstextbox{top: 30px;right: 100px;}
    .beutiFullsecmain{padding-top: 0;}
   }
    @media screen and (max-width:768px){
    .beutiFullsecmain{height: 900px;background: #2d4455;}
    .beftifulmobimgbox{display: block;}
    .beftifulmobimgbox img{width: 100%;}
    .beautifulseimgbox{display: none;}
    .beautifulstextbox .beautifulstrog{font-size: 36px;color: #fff;line-height: 1.4;}
    .beautifulstextbox .beautifulspan{font-size: 36px;color: #fff;display: block;line-height: 1.2;margin-bottom: 16px;}
    .beautifulstextbox .beautifulbtnbutton{padding: 11px 16px; font-size: 16px;border-radius: 2px;width: 100%;}
    .beautifulstextbox{left: 16px;right: 16px;bottom: 20px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;}    
    }
@media screen and (max-width:540px){
    .beftifulmobimgbox{display: block;}
    .beftifulmobimgbox img{width: 100%;}
    .beautifulseimgbox{display: none;}
    .beutiFullsecmain{height: 570px;background: #2d4455;}
    .beautifulstextbox{left: 16px;right: 16px;bottom: 20px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;}
    }