.header{display: block;box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.25);position: sticky;top: 0;z-index: 8;background: #fff;}
.headContainer{padding-left: 120px;position: relative;}
.weblogoBox{position: absolute;left: 0;top: 0;bottom: 0;display: flex;align-items: center;}
.weblogoBox a{cursor: pointer;position: relative;}
.weblogoBox > img{width: 84px;}
.nav{display: flex;align-items: center; justify-content: flex-end;}
.nav .freeButon{height: 65px;display: flex;align-items: inherit;position: relative;display: flex;align-items: center;gap: 5px;white-space: nowrap;margin-left: 20px;display: flex;color: #222;font-size: 14px;cursor: pointer;height: 65px;align-items: center;gap: 10px;}
.nav .freeButon .bookConsultation{padding: 0px 18px;line-height: 35px;font-size: 12px;color: #fff;height: 35px;border-radius: 5px;}
@media screen and (max-width: 540px) {
    .nav .freeButon{cursor: default;user-select: none;}
}