
.funtLowmianbox {display: flex;width: 100%;}
.funMinimgbox{width: 100%;}
.funLftloerbox {width: 40%;background: #ffe5ea;}
.funRgtlowemainbox {width: 60%;background: #f8f8f8;position: relative;}
.funRgtlowemainbox video{vertical-align: middle;}
.funtMiantextbox{padding: 24px 20px;width: 435px;}
.funtMiantextbox span{font-size: 30px;color: #eb5976;margin-bottom: 24px;line-height: 1;display: block;}
.funtMiantextbox P{font-size: 24px;line-height: 1.25;color: #222;font-weight: bold;}
.funmboRepoimbox{display: none;}
.funMinimgbox img{width: 100%;vertical-align: middle;}
.fureJoimgmob{display: none;}
.playButton { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background: transparent; border: none; cursor: pointer; padding: 0;}
.youtubeIcon { width: 80px;  /* Adjust size as needed */ height: auto; transition: transform 0.3s ease-in-out;}
.playButton:hover .youtubeIcon {
    transform: scale(1.1);
}


@media screen and (max-width: 1024px){
    .furnuterJouweb{display: none;}
	.futMinboxhead{margin: 26px 0; } 
	.funtMiantextbox .stepFacthed{margin-bottom: 16px;font-size: 24px;}
	.funtMiantextbox {width: 340px;}
	.funtMiantextbox {left: 24px;top: calc(50% - 110px);}
}
/* @media screen and (max-width: 768px){
	
    .funtMiantextbox .stepFacthed {font-size: 20px;margin-bottom: 5px;}
	.funtMiantextbox p{font-size: 26px;line-height: 30px;}
     .funtMiantextbox {width: 235px;}
} */
@media screen and (max-width: 768px){
    .funturemainBox{margin: 0 -15px;}
	.funmboRepoimbox {display: block;}
	.funMinimgbox {display: none;}
	.funtLowmianbox{flex-direction: column-reverse;}
	.funLftloerbox{width: 100%;}
	.funRgtlowemainbox{width: 100%;}
	/* .funmboRepoimbox img{width: 100%;} */
	.funtMiantextbox{width: 100%;}
	.funtMiantextbox .stepFacthed{font-size: 20px;margin-bottom: 0px;}
	.funtMiantextbox p{font-size: 26px;line-height: 30px;}
	.funtMiantextbox{padding: 16px; left: 0px;top: 0px;}
	.futMinboxhead{margin-bottom: 0px;background: #ffe5ea;}
	.futMinboxhead h3{padding: 16px; color: #222; font-size: 20px;text-align: left;line-height: 24px;}
	.funRgtlowemainbox{border-left: none;}
    .fureJoimgmob{display: block;position: absolute;right: 0;bottom: -4px;}
    .funLftloerbox{position: relative;background: #ffe5ea;}
    .funtMiantextbox span {margin-bottom: 16px;}
   }    