.footer{padding-top: 36px;background-color: #373737;position: relative;}
.footer a{cursor: pointer;}
.footer::before{content: '';position: absolute;right: 0;bottom: 0;width: 127px;height: 278px ;}
.assurance {padding-bottom: 25px;border-bottom: 0.5px solid #747474;}
.assurance ul{display: flex;align-items: center;}
.assurance ul li{margin-left: 15px;padding: 15px;border-radius: 2px;border: solid 0.5px #747474;background-color: #3d3d3d;display: flex;align-items:center;width: 100%;position: relative;min-height: 121px;}
.assurance ul li::after{content: '';position: absolute;right: 0;bottom: 0;width: 51px;height: 69px;}
.assurance ul li:first-child{margin: 0;}
.assurance ul li figure{margin-right: 10px;}
.assurance ul li label{margin-bottom: 10px;display: block;font-size: 16px;color: #c9c9c9;}
.assurance ul li span{font-size: 12px; color: #c9c9c9;line-height: 20px;max-width: 190px;display: inherit;}
.interFootrlogobox{text-align: center;margin-bottom: 16px;}
.copyrightSectn{padding: 15px 0px;border-top: 0.5px solid #747474;color: #fff;text-align: center;}
.slctCntry{display: flex;align-items: center;float: right;position: relative;top: -7px;}

@media screen and (max-width:768px) {
    .footer{padding-top: 15px;}
    .footer::before{display: none;}
    .assurance ul li label{margin-bottom: 8px;}
    .assurance{padding-bottom: 0px;border: none;}
    .assurance ul{display: block;}
    .assurance ul li:first-child{margin: 0px 0px 16px;}
    .assurance ul li{margin: 0px 0px 16px;}
    
}

@media screen and (max-width:540px) {
    .copyrightSectn{padding-bottom: 15px;color: #fff;text-align: center;}
    .assurance ul li figure{margin-right: 12px;}
    .assurance ul li span{font-size: 10px;max-width: 100%;line-height: 16px;}
    .slctCntry{float: inherit;position: inherit;display: inherit;}
    .copyrightSectn .cpyrightDtl{font-size: 12px;}
}