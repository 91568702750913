    .getEstimateweb{padding-top: 60px;}
    .getEstimateLeadform{flex: 0 0 422px;}
    .getEstimateBox{display: flex; align-items: flex-end;}
    .getEstimateLeadform .getEstimateleadhead {display: flex; align-items: center; justify-content: space-between;}
    .estimateLeadtiles{display: flex; gap: 16px; align-items: center;}
    .estimateLeadF{ padding: 24px;border: 1px solid #cbcbcb; border-radius: 10px;}
    .estimateLeadF .estimateTilesBox{display: grid; grid-template-columns: repeat(2,1fr); gap: 24px;position: relative;}
    .estimateLeadtiles{padding: 16px 24px; border: 1px solid #cbcbcb; border-radius: 5px; width: 100%;}
    .getEsripersew{display: flex; gap: 8px; align-items: center;}
    .getEstimastelowBox{display: flex; gap: 24px;position: relative;}
    .estimateLeadF button{ height: 40px; width: 100%; justify-content: center;}
    .estimateList{display: flex; justify-content: space-between;}
    .estimatePosnag{display: flex; align-items: center; gap: 16px;} 
    .getmin{ border-radius: 5px; border: 1px solid #cbcbcb; height: 30px; width: 30px;display: flex; align-items: center;justify-content: center; cursor: pointer;} 
    .getplus{border-radius: 5px; border: 1px solid #cbcbcb; height: 30px; width: 30px;display: flex; align-items: center;justify-content: center; cursor: pointer;}
    .estimateLeadF ul{margin-bottom: 66px;}
    .getEstibuttonlow{display: flex; gap: 24px;}
    .estimateErrorMsg{position: absolute;font-size: 10px;left: 0;bottom: -18px;color: #fd1c1c;line-height: initial;}
    .scopeOfWorkErrorMsg{position: absolute;color: #fd1c1c;font-size: 10px;}
    .getbtback{color: #eb5976; border: 1px solid #eb5976; border-radius: 5px; background: #fff; cursor: pointer;}
    .estimateLeadF .formBox{padding: 0 0 88px 0;}
    .estimateLeadF .otpBox .mainForm{padding: 0;padding-bottom: 30px;display: flex;align-items: center;justify-content: center;flex-direction: column;} 
    .thanksBox p{margin: 0 auto 85px;}
    .estimateLeadF button{display: flex; align-items: center; cursor: pointer;}
    .closeBtn{display: none;}
    .otpBox .mainForm button{display: flex; align-items: center;}
    .otpBox .mainForm form {display: flex;align-items: center;justify-content: space-between;}
    .otpBox .mainForm form input {margin-right: 15px;border-radius: 5px;border: solid 0.5px #cbcbcb;font-size: 16px;color: #000;height: 50px;width: 50px;text-align: center;}

    
    .estimateLeadtiles{cursor: pointer;}
    .estimateLeadtiles.active em svg path ,.estimateLeadtiles:hover em svg path {fill:#eb5976}
    .estimateLeadtiles.active p,.estimateLeadtiles:hover p {color: #eb5976;}
    .estimateLeadtiles.active,.estimateLeadtiles:hover{border: 1px solid #eb5976;}
    
    @media screen and (max-width:768px){
        .getEstimateImgtiles{display: none;}
        .getEstimateLeadform{flex: 0 0 100%;}
    }
    @media screen and (max-width:540px){
        .getEstimateweb{padding-top:15px;}
        .getEstimateleadhead h1{font-size: 16px;}
        .getEstimateleadhead p{font-size: 14px;}
        .closeBtn{display: block;}
        .estimateLeadF{padding: 24px 0px;border: none;}
        .getEstimateleadhead h1{font-size: 16px;}
        .getEstimateleadhead p{font-size: 14px;}
        .estimateLeadF h2{font-size: 14px;}
        .getEsripersew p{font-size: 14px;}
        
    }

    /* long body */
.readMore {padding: 46px 0;margin-top: 80px;}
.readMore p {max-height: 40px;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.readMore .link {cursor: pointer;color: #eb5976;position: relative;top: 5px;}
.lowerContnt {max-height: 70px;overflow: hidden;}
.lowerContnt.fullHeight {max-height: 100%;}
.lowerContnt h2 {margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
.lowerContnt h3 {margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
.lowerContnt label {margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
.lowerContnt span {margin-bottom: 15px;display: block;font-size: 14px;}
.lowerContnt span ul {margin: 15px 0px;}
.lowerContnt span ul li {margin-bottom: 15px;}
.lowerContnt .faqsReadmore {margin-bottom: 15px;font-size: 15px;font-weight: 600;color: #222;}