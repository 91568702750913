 /* banner section start */
 .campginpabannerm{position: relative;height: 680px;padding: 124px 0;}
 .campginpabannerm .cammainBannering{position: absolute;top: 0;left: 0;bottom: 0;right: 0;}
 .campginpabannerm .campingbannermob{display: none;}
 .campginpabannerm .cammainBannering img{width: 100%;height: 680px;}
 .campainLedtextbox{z-index: 2;position: relative;}
 .campainLedtextbox{display: flex;justify-content: space-between;align-items: center;}
 .campTxtbox{width: 725px;}
 .campTxtbox .capmBoxtext{font-size: 60px;line-height: 1.4;color: #fff;font-weight: 900;margin-bottom: 40px;display: block;}
 .campTxtbox .comBoxlowtex{width: 440px;display: block;font-size: 30px;line-height: 1.4;color: #fff;}

 .exitnewFormBox {padding: 24px;background: #fff;position: relative;max-width: 420px;width: 100%;border-radius:  2px;}
 .formControl {height: 42px;}
 .formLable{top: 12px;}
 .btn{padding: 10px;}
 .bookMyconbtbox{display: none;}

 @media screen and (max-width: 540px){
     .campginpabannerm .campingbannermob{position: absolute;top: 0;left: 0;bottom: 0;right: 0;display: block;}
     .campginpabannerm .campingbannermob img{width: 100%;}
     .campginpabannerm .cammainBannering{display: none;}
     .campingLeadbox{display: none;}
     .campTxtbox .capmBoxtext{font-size: 34px;line-height: 1.1;margin-bottom: 20px;}
     .campTxtbox .comBoxlowtex{width: 320px;font-size: 16px;line-height: 1.2;}
     .campTxtbox{width: 320px;}
     .campginpabannerm{padding: 40px 0;height: 400px;}
     .bookMyconbtbox{display: block;width: 100%;}
     .bookMyconbtbox button{width: 100%;padding: 10px;border-radius: 2px;}
     .campainLedtextbox{flex-direction: column;align-items: unset;height: 320px;}

 } 

/* banner section End */