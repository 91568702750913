.getFreeesthead{margin-bottom: 40px;text-align: center;}
.getMoreestimain{display: flex;gap: 24px;margin-bottom: 40px;}
.germoEstiles{padding: 40px; display: flex;align-items: center;gap: 20px;background: #ecf2ff;border: 1px solid #c2d2f7;border-radius: 2px;flex: 1;}
.germoEstiles span{margin-bottom: 10px;font-size: 20px;font-weight: bold;color: #5b78bd;display: block;}
.germoEstiles p{font-size: 20px;color: #000;}
.getMoestmbtbox{width: 100%;display: flex;justify-content: space-between;align-items: center;}
.getMoestmbtbox button {padding: 10px 18px;font-size: 18px;border-radius: 2px;cursor: pointer;}
@media screen and (max-width: 1024px){
  .getMoreestimain{flex-direction: column;gap: 16px;margin-bottom: 20px;}
  .germoEstiles {padding: 20px;}
}
@media screen and (max-width: 540px){
  .getFreeesthead{margin-bottom: 20px;}    
  .germoEstiles p{font-size: 16px;}
  .germoEstiles span{font-size: 14px;margin-bottom: 3px;}
  .germoEstiles img{width: 40px;}
  .getMoestmbtbox{flex-direction: column;gap: 20px;}
  .getMoestmbtbox button{width: 100%;font-size: 14px;}
}
