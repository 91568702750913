 /* Complete Turnkey Start */
 .completServicsminbox{padding: 120px 0 60px 0;background: #f8f8f8;position: relative;}
 .completServicsminbox .completTueyleftim{position: absolute;left: 0;top: 0;}

 .comPletinleftbox{padding: 40px 20px;background: #ffe5ea;border-radius: 2px;}
 .comPletinleftbox span{margin-bottom: 40px;display: block; font-size: 26px;color: #000;font-weight: bold;line-height: 1.2;}
 .comPletinleftbox p{font-size: 20px;color: #666;line-height: 30px;}
 
 .completeyTurkmainb{display: flex;gap: 24px;z-index: 2;position: relative;margin-bottom: 40px;}
 .comPletturyrightm{display: flex;gap: 24px;}
 .completTuertiles{padding: 40px 20px;background: #fff;border-radius: 2px;width: 312px;text-align: center;}
 .comPletturyrightm span{margin: 20px 0; font-size: 20px;font-weight: bold;color: #000;display: block;}
 .comPletturyrightm p{font-size: 14px;color: #666;line-height: 24px;}
 .getFreeedtbt{width: 100%;text-align: center;}
 .getFreeedtbt button{padding: 10px 72px; font-size: 18px;border-radius: 2px;cursor: pointer;}
 .completingLeadbox{display: none;}

 @media screen and (max-width: 1024px){
     .completeyTurkmainb{flex-direction: column;}
 }

 @media screen and (max-width: 540px){
     .completServicsminbox{padding: 40px 0 40px 0}
     .completTuertiles {padding: 16px;min-width: 166px;}
     .completTuertiles img{height: 100px;}
     .completeyTurkmainb{flex-direction: column;gap: 16px;margin-bottom: 40;}
     .completTueyleftim{width: 180px;}
     .comPletinleftbox{padding: 16px;}
     .comPletinleftbox span{font-size: 20px;margin-bottom: 15px;}
     .comPletinleftbox p{font-size: 14px;line-height: 1.2;}
     .comPletturyrightm{overflow: auto;}
     .comPletturyrightm span{font-size: 16px;margin: 16px 0;}
     .comPletturyrightm p{font-size: 12px;line-height: 16px;}
     .getFreeedtbt{display: none;}
     .completingLeadbox{display: block;}
 }
 
/* Complete Turnkey End */