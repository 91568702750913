.youzTubainbox{position: relative;}
.backGrounimg{position: absolute;right: 0;bottom: 0;}
.youinTiles{z-index: 1;}
.youTunesehead{margin-bottom: 40px;}
.youTubemainbox{display: grid;grid-template-columns: repeat(3, 1fr);grid-gap: 24px;gap: 24px;}
.youTileimgbox img{vertical-align: middle;}
.youTileimgbox{position: relative;}
.youTunimgbox{position: absolute;top: 40%;left: 40%;}
.youTubelowertxbox{padding: 16px 20px;background: #fff;border-radius: 0px 0px 2px 2px;}
.youTubelowertxbox p{font-size: 14px;line-height: 18px;color: #666;margin-bottom: 18px;}
.youtuInertxb{display: flex;align-items: center;justify-content: space-between;}
.youtuInertxb span{font-size: 14px;color: #222;}
/* .vidioBox{top: 15%; left: 15%; right: 15%; z-index: 1002; overflow: visible;position: fixed;display: block;} */
.closeModal{color:#666;border:none;background: #fff;align-self: flex-start;cursor: pointer;z-index: 2;right: 32px;top: 40px;position: absolute;width: 24px;height: 24px;border:solid 0.5px #cbcbcb;display: flex;align-items: center;justify-content: center;border-radius: 5px;}
.closeModal::after{content: '';position: absolute;left: 4px;top: 11px;width: 14px;height: 1px;background: #505050;transform: rotate(45deg);}
.closeModal::before{content: '';position: absolute;left: 4px;top: 11px;width: 14px;height: 1px;background: #505050;transform: rotate(135deg);}

@media screen and (max-width: 540px){
   .youTunesehead{margin-bottom: 20px;}
   .youTileimgbox{max-height: 163px;}
   .vidioBox{top: 20%; display: block; position: sticky;}
   .closeModal{right: 15px;}
   .youTubemainbox{display: flex;overflow: auto;}
   .youinTiles{min-width: 288px;}
   .backGrounimg{display: none;}
   .youTubelowertxbox{padding: 16px;}
   .youTubelowertxbox p {font-size: 12px;line-height: 16px;margin-bottom: 12px;}
   .youtuInertxb span{font-size: 12px;}
}
.youTubepopup{height: 100%;padding-top: 26px;}