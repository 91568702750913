 /* in projects start */
 .interProjects{margin-bottom: 60px; padding: 40px 0; background: #1c2f59;position: relative;}
 .intermainledImgb{position: absolute;right: 0;bottom: -5px;}
 .interProjectinmain{display: flex;justify-content: center;}
 .interProjtiles{padding: 0 100px;border-right: 1px solid #f8f8f8;text-align: center;}
 .interProjtiles img{min-height: 45px;max-width: 45px;}
 .interProjtiles:last-child{border: none;}
 .interProjtiles span{white-space: nowrap;}
 @media screen and (max-width: 1024px){
     .interProjtiles{padding: 0 50px;};
 }
 @media screen and (max-width: 540px){
     .interProjects{padding: 0;}
     .interProjtiles{padding: 20px 0;border: 1px solid #7c97d4;}
     .intermainledImgb{display: none;}
     .interProjectinmain{display: grid;grid-template-columns: repeat(2, 1fr);gap: 0px;margin: 0 -15px;}
     .interProjtiles:last-child{border: 1px solid #7c97d4;}
     .bdLeftno{border-left: none;}
     .bdRightno{border-right: none;}
     .bdTopno{border-top: none;}
     .bdBottomno{border-bottom: none;}
     
 }
/* in projects End */