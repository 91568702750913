.beForafsehead{margin-bottom: 40px;}
.adBefleftbox{width: 50%;}
.afterBeforightimg{width: 50%;}
 .beForafmainbox{display: flex;position: relative;margin-bottom: 40px;}
 .sliderImgbox{position: absolute;left: calc(50% - 30px) ;top: calc(50% - 30px);}
 .tranFeryobtbox{width: 100%;text-align: center;}
 .tranFeryobtbox button{padding: 11px 16px;font-size: 18px;border-radius: 2px;cursor: pointer;}

 @media screen and (max-width: 540px){
     .beForafsehead{margin-bottom: 20px;}
     .adBefleftbox img{width: 200%;height: 220px;}
     .afterBeforightimg img{width: 100%;height: 220px;}
     .beForafmainbox{height: 220px;margin-bottom: 20px;}
     .sliderImgbox{position: absolute;left: calc(50% - 15px) ;}
     .sliderImgbox img{width: 30px;}
     .tranFeryobtbox button{width: 100%;font-size: 14px;}
}

.imageComparisonContainer{width:100%;margin:0 auto;position:relative;user-select:none;-webkit-user-select:none;margin-bottom: 40px;}
.imageComparisonWrapper{position:relative;overflow:hidden;width:100%;height:auto}
.beforeImage,.afterImage{width:100%;height:auto;display:block;pointer-events:none;user-select:none;-webkit-user-select:none}
.afterImage{position:absolute;top:0;left:0;clip-path:polygon(0 0,var(--slider-position,50%) 0,var(--slider-position,50%) 100%,0 100%);z-index:2}
.slider{position:absolute;-webkit-appearance:none;appearance:none;width:100%;height:100%;background:transparent;outline:none;margin:0;padding:0;z-index:6;top:0;left:0;cursor:col-resize}
.slider::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;width:0;height:100%}
.slider::-moz-range-thumb{width:0;height:100%;border:none;background:transparent}
.sliderHandle{position:absolute;top:50%;transform:translate(-50%,-50%);z-index:4;width:62px;height:62px;display:flex;align-items:center;justify-content:center;cursor:col-resize}
.sliderImgbox{pointer-events:none;user-select:none;-webkit-user-select:none}
.sliderImgbox img{pointer-events:none;user-select:none;-webkit-user-select:none}
@media (max-width: 768px) {
.sliderHandle{width:50px;height:50px}
.beforeImage,.afterImage{height: 220px;}
}