.meadiaheadbox{margin-bottom: 40px;}
.medaimainbox{display: flex;gap: 24px;}
.medaiTiles{padding: 20px 16px;background: #fff;border-radius: 2px;width: 244px;}
.medaiTiles img{vertical-align: middle;margin-bottom: 18px;max-height: 40px;}
.medaiTiles p{font-size: 16px;line-height: 20px;color: #222;}
@media screen and (max-width: 1024px){
 .medaimainbox{overflow: auto;}
 .medaiTiles{min-width: 312px;}
}
@media screen and (max-width: 540px){
 .meadiaheadbox{margin-bottom: 20px;}
 .medaimainbox{flex-direction: column;gap: 16px;align-items: center;}
 .medaiTiles{padding: 16px;width: 100%;}
 .medaiTiles p{font-size: 12px;line-height: 16px;}
}