.whychoInermain{padding: 40px 0;display: flex;width: 100%;justify-content: center;}
.whyChoeslefttiles{padding: 0 40px;border-right: 1px solid #999;text-align: center;}
.whyChoeslefttiles span{white-space: nowrap;}

.whyChoeinleft{display: flex;}
.whyChoesright{padding: 0 40px;text-align: center;}
.whyChoesright span{white-space: nowrap;}

.bokkConsbtbox{width: 100%;text-align: center;}
.bokkConsbtbox button{padding: 11px 40px;font-size: 18px;border-radius: 2px;cursor: pointer;}
@media screen and (max-width: 1024px){
 .whyChoeslefttiles{padding: 0 15px;border-right: 1px solid #999;text-align: center;}
}
@media screen and (max-width: 540px){
 .whychoInermain{flex-direction: column;}
 .whyChoeslefttiles{border: 1px solid #eb5976;padding: 16px 0px;}
 .whyChoeinleft{display: grid;grid-template-columns: repeat(2, 1fr);gap: 0px;margin: 0 -15px;}
 .whyChoesright{padding: 16px 0;border: 1px solid #eb5976;margin: 0 -15px;border-bottom: 1px solid #eb5976;}
 .whychoInermain{padding: 20px 0;}
 .bokkConsbtbox button{font-size: 14px;width: 100%;}
 .bdLeftno{border-left: none;}
 .bdRightno{border-right: none;}
 .bdTopno{border-top: none;}
 .bdBottomno{border-bottom: none;}

}