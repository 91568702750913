.googleRewvbtbox{width: 100%;text-align: center;}
.googleRewvbtbox button{padding: 11px 17px;font-size: 18px;border-radius: 2px;}
@media screen and (max-width: 540px){
    .googleRewvbtbox button{width: 100%;}
}
 /* google reviwe page start */
 .googleReviewSection{padding: 40px 0px;background-size: cover;}
 .googleReviweBox{padding-bottom: 34px;display: flex;gap: 24px;margin-bottom: 40px;position: relative;}
 .googleReviweBox .starBox ul{display: flex;}
 .googleReviweBox .rightReviewBox{border-left: 1px solid #666;width: 100%;max-width: calc(100% - 260px);}
 .googleReviweBox .rightrateviewBox{margin-left: 24px;}
 .googleReviweBox .ratingpoinBox{display: flex; gap: 10px; align-items: baseline;}
 .googleReviweBox .ratingpoinBox ul{display: flex;}
 .googleReviweBox .ratingpoinBox ul li{width: 18px; height: 18px;}
 .googleReviweBox .ratingpoinBox p{line-height: 22px;}
 .googleReviweBox .ratingpoinBox .rating{width: 110px;background-size: 116px;height: 18px;top: 5px;background-position: -5px -5px;}
 .googleReviweBox .ratingpoinBox .ratingColor{width: 110px;background-size: 116px;height: 18px;top: 0px;background-position: -5px -28px;}
.googleReviweBox .rightRaterewvirlower{margin:  16px 0px 0 24px;}
.googleReviweBox .leftRewviwBox{display: flex;flex-direction: column; align-items: center;}
.googleReviweBox .leftRewviwBox span{display: block; line-height: normal;}
.googleReviweBox .typeOfModular{margin-bottom: 14px;display: flex;}
.googleReviweBox .typeOfModular ul{padding-bottom: 10px;overflow: auto;width: 100%;white-space: nowrap;}
.googleReviweBox .typeOfModular ul :first-child{margin-left: 0;}
.googleReviweBox .typeOfModular li{margin-left: 16px;padding: 0px 10px;display: inline-block;border-radius: 5px;border: solid 0.5px #666;line-height: 30px;cursor: pointer;font-size: 12px;}
.googleReviweBox .typeOfModular li.active{color: #eb5976;}
.googleReviweBox .leftRewviwBox img{margin-left: 8px;}
.googleReviweBox .userFulldeatlis{padding-bottom: 15px;margin: 0px 30px 15px 0px;border-bottom: 1px solid #666;}
.googleReviweBox .userFulldeatlis:last-child {padding: 0;margin: 0;border: none;}
.googleRewviwanker{position: absolute;right: 0;bottom: 0;}
.googleRewviwanker a{font-size: 14px;}
.usergoogleRewBox{max-height: 335px; height: 100%;overflow: auto;}
.usergoogleRewBox::-webkit-scrollbar {width: 4px;}
.usergoogleRewBox::-webkit-scrollbar-track {background: #f8f8f8;}
.usergoogleRewBox::-webkit-scrollbar-thumb {background: #e1e1e1;}
.rating{display: block;height: 22px;width: 170px;position: relative;top: 1px;background: url('../../../public/assets/images/ratingSpriteImage.svg') no-repeat;background-size: 182px;transition: .3s;background-position: -7px -7px;}
.rating .ratingColor{display: block;height: 22px;position: absolute;background: url('../../../public/assets/images/ratingSpriteImage.svg') no-repeat;background-size: 182px;transition: .3s;background-position: -7px -44px;}

@media screen and (max-width:768px){
	.googleReviweBox .rightReviewBox{border: none;max-width: 100%;}
	.reviweHeading{text-align: center;}
	.reviwePara{text-align: center;}
}
@media screen and (max-width:540px){
	.reviweHeading{font-size: 20px;  line-height: 24px;}
	.reviwePara{font-size: 14px; line-height: 20px; margin-bottom: 16px;}
	.googleReviweBox{margin: 0px;padding: 0px;flex-direction: column;}
	.googleReviweBox .basedOnReviews{padding-left: 0;margin: 0;border: none;}
	.googleReviweBox .userCommnet{font-size: 12px; line-height: 18px;}
	.googleReviweBox .leftRewviwBox{padding: 15px;gap: 15px;width: 100%;  border-radius: 20px;background-color: #f3f3f3;}
	.googleReviweBox .leftRewviwBox img{margin: 0;}
	.googleReviweBox .rightrateviewBox{margin: 0;}
	.googleReviweBox .reviewBased{font-size: 14px;}
	.googleReviweBox .leftRewviwBox span{font-size: 50px;}
	.googleReviweBox .typeOfModular{margin: 0;}
	.googleReviweBox .typeOfModular ul{padding-bottom: 20px;}
	.googleReviweBox .typeOfModular li{padding:0px 5px 0px 0px;margin: 0px 5px 0px 0px;line-height: normal;border: none;border-right: 1px solid #222;border-radius: 0px;}
	.googleReviweBox .typeOfModular li:last-child{border:none;margin: 0px;padding: 0px;}
	.googleReviweBox .typeOfModular li.active{background-color: inherit;border-color: none;}
	.googleRewviwanker{right: 15px;bottom: -20px;}
}
@media screen and (max-width:360px){
	.googleReviweBox .basedOnReviews{flex-direction: column;}
}

/* google reviwe page End */